import { useState } from 'react';
import wurd from 'wurd-react';
import pick from 'lodash/pick';
import store from 'store';
import * as actions from 'actions';
import Button from 'components/step-button';
import Field from 'components/field';
import CustomFields from 'plugins/custom-fields/fields';
import Captcha from 'plugins/captcha';
import useForm from 'utils/useForm';
import Paper from 'components/paper';
import Block from 'components/block';
import Title from 'components/title';
import ErrMsg from 'components/err-msg';
import TermsCheck from 'components/terms-check';
import GoogleButton from 'components/google-button';


const cms = wurd.block('unitOrder.account');

export default function UnitOrderAccountStep({
  site,
  unitType,
  unit, // nullable
  date,
  order,
  nextStep,
}) {
  const { user, settings } = store.get();
  const [err, setErr] = useState();
  const [showLogin, setShowLogin] = useState();

  const signupForm = useForm({
    initialValue: pick(user, ['firstName', 'lastName', 'email', 'phone', 'address', 'customFields']),
    onSubmit: async function (data, { email, password, ...patchData }) {
      if (user) {
        await actions.user.update(patchData);
      } else {
        await actions.user.signup(data);
      }
    },
    cms,
  }, [user]);

  const signinForm = useForm({
    onSubmit: actions.user.signin,
    cms,
  });

  async function submit(e) {
    e.preventDefault();

    if (showLogin) {
      await signinForm.submit();
      setShowLogin(false);
      return;
    }

    await signupForm.submit();

    const sp = new URLSearchParams(location.search);
    const err = await nextStep({
      siteId: site.id,
      unitTypeId: unitType.id,
      unitId: unit?.id,
      startDate: date,
      promoCode: sp.get('promoCode') || sp.get('promo') || undefined,
    });

    if (err) setErr(err);
  }

  return (
    <Block as="form" onSubmit={submit} key={showLogin}>
      <Title cms={cms.block('title')} className="mt-5" />

      <Paper padded className="mt-3">
        <Captcha />
        {!user && (
          <div className="text-end">
            <button
              type="button"
              className="btn btn-sm btn-tertiary"
              onClick={() => setShowLogin(!showLogin)}
            >
              <cms.Text id={showLogin ? 'signup' : 'login'} />
            </button>
          </div>
        )}

        {showLogin
          ? (
            <>
              <Field
                {...signinForm.fieldProps('email', '')}
                required
                autoComplete="username"
              />
              <Field
                {...signinForm.fieldProps('password', '')}
                required
                type="password"
                autoComplete="current-password"
              />
            </>
          ) : (
            <>
              <div className="d-flex flex-column">
                <Field {...signupForm.fieldProps('firstName')} required />
                <Field {...signupForm.fieldProps('lastName')} required />
                <Field
                  {...signupForm.fieldProps('email')}
                  value={user?.email}
                  required
                  type="email"
                  autoComplete="username"
                />
                {!user && <Field {...signupForm.fieldProps('password')} required type="password" autoComplete="current-password" />}
                <Field {...signupForm.fieldProps('phone')} required />
                <Field {...signupForm.fieldProps('address')}>
                  <textarea required />
                </Field>
                <CustomFields
                  fieldProps={signupForm.fieldProps}
                  fieldSettings={settings.userCustomFields}
                  mode={user ? 'update' : 'create'}
                />
              </div>
              {!user && (
                <>
                  <TermsCheck cms={cms} className="mt-4" />
                  <GoogleButton signup />
                </>
              )}
            </>
          )}

        {signupForm.err && !showLogin && <ErrMsg err={signupForm.err} className="mt-2" />}

        {signinForm.err && showLogin && <ErrMsg err={signinForm.err} className="mt-2" />}

        {err && <ErrMsg err={err} className="mt-2" />}
      </Paper>

      {/* TODO fix the button is not disabled/loading after submitting form with a long job.unit_moveIn.create_started webhook, POST /unit-orders is still pending but button is back clickable */}
      <Button type="submit" loading={signupForm.isLoading || signinForm.isLoading}><cms.Text id="submitText" /></Button>
    </Block>
  )
}
