import { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import Modal from 'react-bootstrap/Modal';
import styled from '@emotion/styled';
import store from 'store';
import wurd from 'wurd-react';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import * as actions from 'actions';
import Loader from 'components/loader';
import Paper from 'components/paper';
import Title from 'components/title';
import Button from 'components/button';
import ErrMsg from 'components/err-msg';
import { apiUrl } from 'utils/api';
import { getPrice, datePeriod } from 'utils/ui';
import { hasBillingMethod } from 'utils/user';
import useSearchData from 'utils/useSearchData';
import { StateLabel, stateStyles } from './invoice-list';
import ItemDescription from 'components/invoice/item-description';


const cms = wurd.block('invoice');


const Container = styled.div({
  maxWidth: 750,
});

const HeaderTable = styled.table({
  td: {
    padding: '0 .5rem 0 .25rem'
  },
  'td:first-of-type': {
    width: '50%'
  }
});

const ContentTable = styled.table({
  fontSize: '.875rem',
  color: 'var(--bs-gray-dark)',
  th: {
    borderBottomColor: 'inherit!important',
    paddingRight: '.5rem',
  },
  td: {
    padding: '1.375rem .5rem 1.375rem .25rem',
    verticalAlign: 'middle'
  },
  'td:last-of-type, th:last-of-type': {
    textAlign: 'right',
  },
  h3: {
    margin: '1.5rem 0 0 .25rem',
    fontWeight: 'normal',
    fontSize: '1.25rem',
  }
});

const TotalTable = styled.table({
  marginLeft: 'auto',
  fontSize: '.875rem',
  td: {
    textAlign: 'right',
    padding: '1.375rem 1rem 1.375rem .25rem',
  },
  'td:last-of-type': {
    paddingLeft: '1.5rem',
  },
  'tr:not(:last-of-type) > td': {
    paddingBottom: '.5rem'
  },
  'tr:not(:first-of-type) > td': {
    paddingTop: '.5rem'
  },
  '& > :not(:last-child) > :last-child > *': {
    borderBottom: '2px solid currentColor'
  }
});


export default function InvoiceDetailPage() {
  const params = useParams();
  const sid = params.sid.toUpperCase();
  const [query, setQuery] = useSearchData();
  const [showPay, setShowPay] = useState(query.pay !== undefined);
  const { data: invoice, isLoading, refetch } = useQuery(`invoice-${params.sid}`, () => actions.invoices.get(params.sid).catch(() => null));

  useEffect(() => {
    if (showPay) {
      setQuery({ ...query, pay: '' }, { replace: true });
    } else {
      setQuery(Object.fromEntries(Object.entries(query).filter(([k]) => k !== 'pay')), { replace: true });
    }
  }, [showPay]);

  if (isLoading) return <Loader />;
  if (!invoice) return <cms.Markdown id="404" className="text-center mt-5 pt-5" />;
  const { settings, user } = store.get();

  const lines = [
    ...invoice.items?.map(item => ({ ...item, date: item.startDate, qty: item.quantity, amount: item.price })) || [],
    ...invoice.entries || [],
  ];

  const unit = invoice.unitRentalId && user?.units.find(unit => unit.rental?.id === invoice.unitRentalId);
  const invoiceUnitTitle = unit?.name.toUpperCase(); // when unit title is empty, it means the site was updated to hidden

  return (
    <Container className="container my-2">
      <div className="d-flex align-items-center justify-content-between">
        <Button
          as={Link}
          to="/account/invoices"
          variant="link"
          className="fs-7 btn-sm text-decoration-none d-flex align-items-center"
        >
          <i className="fal fa-angle-left me-2 fs-4" />
          <cms.Text id="back" />
        </Button>
        <Button
          as="a"
          href={`${apiUrl}/v1/invoices/${sid}.pdf`}
          target="_blank"
          variant="link"
          className="fs-7 btn-sm text-decoration-none"
        >
          <cms.Text id="print" />
        </Button>
      </div>
      <Title cms={cms.block('title')} vars={{ sid }} />

      <Paper>
        <div className="p-3 pt-4 px-sm-5 border-bottom border-2 border-primary">
          <HeaderTable className="w-100">
            <tbody>
              <tr>
                <td><cms.Text id="date" type="small" className="text-muted" /></td>
                {invoiceUnitTitle && <td className="d-none d-sm-table-cell"><cms.Text id="unit" type="small" className="text-muted" /></td>}
                <td className="d-none d-sm-table-cell"><cms.Text id="number" type="small" className="text-muted" /></td>
                {invoice.state !== 'sent' && <td className="d-none d-sm-table-cell text-end"><cms.Text id="state" type="small" className="text-muted" /></td>}
              </tr>
              <tr>
                <td><span className="fs-2 text-nowrap">{moment(invoice.startDate).format(settings.dateFormats.short)}</span></td>
                {invoiceUnitTitle && <td className="d-none d-sm-table-cell"><span className="text-nowrap">{invoiceUnitTitle}</span></td>}
                <td className="d-none d-sm-table-cell"><span className="text-uppercase">{invoice.sid}</span></td>
                {invoice.state !== 'sent' && <td className="d-none d-sm-table-cell text-end"><StateLabel style={stateStyles[invoice.state]}><cms.Text id={invoice.state} /></StateLabel></td>}
              </tr>
              <tr className="d-sm-none">
                {invoiceUnitTitle && <td><cms.Text id="unit" type="small" className="text-muted" /></td>}
                <td><cms.Text id="number" type="small" className="text-muted" /></td>
                {invoice.state !== 'sent' && <td className="text-end"><cms.Text id="state" type="small" className="text-muted" /></td>}
              </tr>
              <tr className="d-sm-none">
                {invoiceUnitTitle && <td><span className="text-nowrap">{invoiceUnitTitle}</span></td>}
                <td><span className="text-uppercase">{invoice.sid}</span></td>
                {invoice.state !== 'sent' && <td className="text-end"><StateLabel style={stateStyles[invoice.state]}><cms.Text id={invoice.state} /></StateLabel></td>}
              </tr>
            </tbody>
          </HeaderTable>
        </div>

        <div className="p-3 px-sm-5 overflow-hidden">
          <ContentTable className="table">
            <thead>
              <tr>
                <th><h3><cms.Text id="yourOrder" /></h3></th>
                <th><cms.Text id="invoiceDetails.headerPrice" /></th>
                <th><cms.Text id="invoiceDetails.headerTax" /></th>
                <th><cms.Text id="invoiceDetails.headerTotal" /></th>
              </tr>
            </thead>
            <tbody>
              {lines.map(line => (
                <tr key={line.id}>
                  <td>
                    <div className="fw-600"><ItemDescription>{line.desc}</ItemDescription></div>
                    <div className="text-muted">{datePeriod(line.date, line.endDate, { tags: line.tags }).join(' - ')}</div>
                  </td>
                  <td className="text-muted">
                    {line.qty > 1 ? `${line.qty} x ` : null}{getPrice(line.amount)}
                  </td>
                  <td className="text-muted">
                    {line.tax ? getPrice(line.tax) : <cms.Text id="noTax" />}
                  </td>
                  <td>
                    {getPrice(line.total)}
                  </td>
                </tr>
              ))}
            </tbody>
          </ContentTable>

          <TotalTable>
            <tbody>
              <tr>
                <td><cms.Text id="subtotal" /></td>
                <td><strong>{getPrice(invoice.subtotal)}</strong></td>
              </tr>
              {invoice.tax !== 0 && (
                <tr>
                  <td><cms.Text id="tax" /></td>
                  <td><strong>{getPrice(invoice.tax)}</strong></td>
                </tr>
              )}
            </tbody>

            <tbody>
              <tr>
                <td><cms.Text id="total" /></td>
                <td><strong className="fs-5">{getPrice(invoice.total)}</strong></td>
              </tr>
              <tr>
                <td><cms.Text id="amountPaid" /></td>
                <td><strong>{getPrice(invoice.amountPaid)}</strong></td>
              </tr>
            </tbody>

            <tbody>
              <tr>
                <td><cms.Text id="amountDue" type="strong" className="fs-5" /></td>
                <td><strong className="fs-2">{getPrice(invoice.balance)}</strong></td>
              </tr>
            </tbody>
          </TotalTable>

          {(invoice.state === 'sent' || invoice.state === 'failed') && invoice.balance > 0 && (
            <Button variant="primary" className="float-end mb-2" onClick={() => setShowPay(true)}>
              <cms.Text id="pay" />
            </Button>
          )}
        </div>
      </Paper>
      <div>
        <cms.Markdown id="notes" />
      </div>

      <Modal id="invoicePaymentModal" show={showPay} onHide={() => setShowPay(false)}>
        <button type="button" className="btn text-primary" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowPay(false)}><i className="text-white fal fa-times fa-2x" /></button>
        <Modal.Body>
          <InvoicePaymentModalBody invoice={invoice} onSuccess={refetch} />
        </Modal.Body>
      </Modal>
    </Container>
  )
}


function InvoicePaymentModalBody({ invoice, onSuccess }) {
  const payMutation = useMutation(() => actions.invoices.pay(invoice.id).then(onSuccess));

  if (payMutation.isSuccess) {
    return (
      <div className="alert alert-success mt-2 d-flex gap-2 align-items-baseline" role="alert">
        <i className="fas fa-check" />
        <cms.Markdown id="payModal.success" />
      </div>
    );
  }

  const { settings, user } = store.get();
  const hasPM = hasBillingMethod(settings, user);

  return (
    <>
      {hasPM
        ? <cms.Markdown id="payModal.text" vars={{ amountDue: getPrice(invoice.balance) }} />
        : <cms.Markdown id="payModal.noPayMethod" />
      }

      {payMutation.error && <ErrMsg err={payMutation.error} className="mt-2" />}

      {hasPM && !payMutation.error && (
        <Button loading={payMutation.isLoading} onClick={payMutation.mutate} className="mt-4">
          <cms.Text id="payModal.process" />
        </Button>
      )}
    </>
  );
}
